import { amber, blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const THEMES = {
  production: createTheme({
    palette: {
      secondary: red,
      mode: "light",
    },
  }),
  staging: createTheme({
    palette: {
      secondary: amber,
      mode: "light",
    },
  }),
  development: createTheme({
    palette: {
      secondary: blue,
      mode: "light",
    },
  }),
};

const currentEnvironment = process.env.REACT_APP_ENV || "development";

export const THEME = THEMES[currentEnvironment];

export const currentTheme = {
  ...THEME,
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "&.RaMenuItemLink-active": {
            backgroundColor: THEME.palette.secondary?.A400,
            color: "white",
          },
        },
      },
    },
  },
};
