import { React, useState, useEffect } from "react";
import {
  Form,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  Title,
  useGetOne,
} from "react-admin";
import { useInput } from "../../../../lib/Hooks";
import { ActionButton } from "../../CustomComponents/ActionButton";
import {
  Box,
  Input,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  Link,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

function saveTxtToFile(fileName, textData) {
  const blobData = new Blob([textData], { type: "text/plain" });
  const urlToBlob = window.URL.createObjectURL(blobData);

  const a = document.createElement("a");
  a.style.setProperty("display", "none");
  document.body.appendChild(a);
  a.href = urlToBlob;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(urlToBlob);
  a.remove();
}

const UtilityView = function UtilityView() {
  const [selectedCompany, updateSelectedCompany] = useInput();
  const [dataLoaderCompanyId, setDataLoaderCompanyId] = useState("");
  const [dataLoaderLoadFromEnvironment, setDataLoaderLoadFromEnvironment] =
    useState("production");
  const [companyData, setCompanyData] = useState("");
  const [benefitsRegistryCompanyData, setBenefitsRegistryCompanyData] =
    useState("");
  const [skipBenefitsRegistry, setSkipBenefitsRegistry] = useState(false);
  const [manualDataLoad, setManualDataLoad] = useState(false);
  const environment = process.env.REACT_APP_ENV;
  const loadFromEnvironments = ["staging", "production"];
  const validLoadFromEnvironments = loadFromEnvironments.filter(function (
    loadFromEnvironment
  ) {
    return loadFromEnvironment !== environment;
  });
  const urlPrefix =
    dataLoaderLoadFromEnvironment === "production"
      ? "https://api.tools.benefits.secure.justworks.com"
      : "https://api.tools.benefits.staging.justworks.com";
  const sanitizedDataUrl = `${urlPrefix}/api/v0/sanitizer/company_data/${dataLoaderCompanyId}`;
  const benefitsRegistryDataUrl = `${urlPrefix}/api/v0/benefits_registry_company_data/${dataLoaderCompanyId}`;

  useEffect(() => {
    setSkipBenefitsRegistry(dataLoaderLoadFromEnvironment === "staging");
  }, [dataLoaderLoadFromEnvironment]);

  const LoadedCompany = () => {
    const { data } = useGetOne(
      "companies",
      {
        id: dataLoaderCompanyId,
      },
      { retry: true, enabled: !!dataLoaderCompanyId }
    );
    return (
      <div>
        <strong>Loaded company: </strong>
        {data && (
          <ReferenceField
            record={data}
            source="id"
            reference="companies"
            label="Company"
            link="show"
          />
        )}
      </div>
    );
  };

  const companyLabel =
    dataLoaderCompanyId.length > 1
      ? `Company ${dataLoaderCompanyId}`
      : "your selected company";

  const saveFile = (fileName) => {
    return ({ data }) => saveTxtToFile(fileName, data);
  };

  return (
    <div>
      <Title title="Utilities" />
      <Box>
        <h4>Sanitized Data Dumper</h4>
        <Form>
          <ReferenceInput
            sort={{ field: "name", order: "ASC" }}
            label="company"
            reference="companies"
            source="name"
            perPage={15000}
          >
            <SelectInput
              onChange={updateSelectedCompany}
              source="name"
              optionText="name"
            />
          </ReferenceInput>
        </Form>
        <ActionButton
          label="Dump selected company"
          method="GET"
          resource="sanitizer"
          id={selectedCompany}
          disabled={!selectedCompany}
          callback={saveFile(`company_seed_data_${selectedCompany}.rb`)}
        />
        <br />
        <ActionButton
          label="Dump all companies"
          method="GET"
          resource="sanitizer"
          id="all"
          callback={saveFile(`company_seed_data_all.rb`)}
        />
      </Box>
      {environment !== "production" && (
        <Box>
          <h4>Data Loader</h4>
          <div>
            Running the data loader kicks off a job that will:
            <ul>
              <li>
                Wipe all existing company-specific data for {companyLabel} from{" "}
                {environment}
              </li>
              <li>
                Load sanitized data for {companyLabel} from{" "}
                {dataLoaderLoadFromEnvironment} to {environment}
              </li>
              {!skipBenefitsRegistry && (
                <span>
                  <li>
                    Close all existing company-specifc benefits registry data
                    for {companyLabel} in staging
                  </li>
                  <li>
                    Load company-specifc benefits registry data for{" "}
                    {companyLabel} from production to staging
                  </li>
                </span>
              )}
            </ul>
          </div>
          <FormGroup sx={{ m: 1, maxWidth: 500 }}>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="load-from-environment-label">
                Load From Environment
              </InputLabel>
              <Select
                label="Load From Environment"
                labelId="load-from-environment-label"
                onChange={(event) =>
                  setDataLoaderLoadFromEnvironment(event.target.value)
                }
                value={dataLoaderLoadFromEnvironment}
              >
                {validLoadFromEnvironments.map((environment, index) => {
                  return (
                    <MenuItem key={index} value={environment}>
                      {environment}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <Input
                placeholder="Company UUID"
                type="text"
                onChange={(event) => setDataLoaderCompanyId(event.target.value)}
              />
            </FormControl>
            <FormControlLabel
              sx={{ m: 1, minWidth: 200 }}
              size="small"
              control={
                <Checkbox
                  checked={skipBenefitsRegistry}
                  onChange={(event) =>
                    setSkipBenefitsRegistry(!skipBenefitsRegistry)
                  }
                />
              }
              label="Skip Benefits Registry"
            />
            <FormControlLabel
              sx={{ m: 1, minWidth: 200 }}
              size="small"
              control={
                <Checkbox
                  checked={manualDataLoad}
                  onChange={(event) => setManualDataLoad(!manualDataLoad)}
                />
              }
              label="Load data manually"
            />
            {manualDataLoad && (
              <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                  <Link target="_blank" href={sanitizedDataUrl}>
                    Get Sanitized Data From {dataLoaderLoadFromEnvironment}
                  </Link>
                  <TextField
                    multiline
                    maxRows={10}
                    label="Paste Sanitized Data"
                    onChange={(event) => setCompanyData(event.target.value)}
                    value={companyData}
                  />
                </FormControl>
                {!skipBenefitsRegistry && (
                  <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <Link target="_blank" href={benefitsRegistryDataUrl}>
                      Get Benefits Registry Data From{" "}
                      {dataLoaderLoadFromEnvironment}
                    </Link>
                    <TextField
                      multiline
                      label="Paste Benefits Registry Data"
                      maxRows={10}
                      onChange={(event) =>
                        setBenefitsRegistryCompanyData(event.target.value)
                      }
                      value={benefitsRegistryCompanyData}
                    />
                  </FormControl>
                )}
              </FormGroup>
            )}
            <ActionButton
              label="Load data"
              method="POST"
              resource="data_loader"
              id={dataLoaderCompanyId}
              successMessage={"Data loader kicked off!"}
              bodyData={{
                load_from_environment: dataLoaderLoadFromEnvironment,
                company_data: companyData,
                skip_benefits_registry: skipBenefitsRegistry,
                benefits_registry_company_data: benefitsRegistryCompanyData,
              }}
              disabled={!dataLoaderCompanyId}
            />
            <LoadedCompany />
          </FormGroup>
        </Box>
      )}
    </div>
  );
};

UtilityView.propTypes = {};

UtilityView.defaultProps = {};

export default UtilityView;
