import React from "react";

import { Datagrid, TextField, ReferenceField } from "react-admin";

import { ConvertSelectionButton } from "../../../ConvertSelectionButton";

import FullNameLink from "./FullNameLink";

const sort = { field: "last_name", order: "ASC" };

const WaitingForEnrollmentCreationPrompt =
  function WaitingForEnrollmentCreationPrompt({ data }) {
    const {
      selections_to_enroll: selectionsToEnroll,
      enrolled_selections: enrolledSelections,
      declined_selections: declinedSelections,
    } = data;

    return (
      <div>
        {selectionsToEnroll[0] && (
          <div>
            <h4>Selections pending enrollment:</h4>
            <Datagrid data={selectionsToEnroll} sort={sort}>
              <FullNameLink
                source="member_id"
                reference="members"
                label="Member"
              />
              <TextField source="selection_status" />
              <ReferenceField
                source="company_plan_offering_id"
                reference="company_plan_offerings"
                label="Plan"
                link="show"
              >
                <TextField source="plan_name" />
              </ReferenceField>
              <TextField
                source="incomplete_enrollment_prerequisites"
                label="Incomplete Prerequisites"
              />
              <ConvertSelectionButton label="Manual Enroll" />

              <TextField source="coverage_tier" label="Enrollment Tier" />

              <ReferenceField
                source="company_plan_offering_id"
                reference="company_plan_offerings"
                label="Plan Code"
                link="show"
              >
                <TextField source="plan_code" />
              </ReferenceField>
            </Datagrid>
          </div>
        )}
        {enrolledSelections[0] && (
          <div>
            <h4>Selections with completed enrollments:</h4>
            <Datagrid data={enrolledSelections} sort={sort}>
              <FullNameLink
                source="member_id"
                reference="members"
                label="Member"
              />
              <ReferenceField
                source="company_plan_offering_id"
                reference="company_plan_offerings"
                label="Plan"
                link="show"
              >
                <TextField source="plan_name" />
              </ReferenceField>
              <TextField source="selection_status" />
            </Datagrid>
          </div>
        )}
        {declinedSelections[0] && (
          <div>
            <h4>Declined Selections:</h4>
            <Datagrid data={declinedSelections} sort={sort}>
              <FullNameLink
                source="member_id"
                reference="members"
                label="Member"
              />
              <TextField source="selection_status" />
            </Datagrid>
          </div>
        )}
      </div>
    );
  };

export default WaitingForEnrollmentCreationPrompt;
