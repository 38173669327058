import HttpClient from "lib/HttpClient";

function formFieldType(propertySchemaConfig) {
  if (propertySchemaConfig.relation === "many") {
    return "checkbox";
  } else if (
    propertySchemaConfig.association ||
    Object.keys(propertySchemaConfig).includes("enum")
  ) {
    return "dropdown";
  } else if (propertySchemaConfig.format === "date") {
    return "datePicker";
  } else {
    return "text";
  }
}

function formFieldLabel(propertyName, propertySchemaConfig) {
  if (propertySchemaConfig.association) {
    const associationName = snakeCaseToCapitalized(
      propertyName.split("_id")[0]
    ); // "provider_id" -> "Provider"
    const associationIdentifier = snakeCaseToCapitalized(
      propertySchemaConfig.identifier
    ); // "start_date" -> "Start Date"
    return associationName + " " + associationIdentifier; // "Provider Start Date"
  } else {
    return snakeCaseToCapitalized(propertyName);
  }
}

function snakeCaseToCapitalized(sentence) {
  return sentence
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function checkboxLabel(option) {
  if (option.provider_name) {
    return `[${option.provider_name}] ${option.label}`;
  } else {
    return option.label;
  }
}

async function formDropdownOptions(propertySchemaConfig, baseApiUrl) {
  if (propertySchemaConfig.association) {
    const associationUrl = `${baseApiUrl}/${propertySchemaConfig.endpoint}`;
    const { json: options } = await HttpClient(associationUrl);
    return options.map((option) => ({
      ...option,
      label: option[propertySchemaConfig.identifier],
    }));
  } else if (Object.keys(propertySchemaConfig).includes("enum")) {
    return propertySchemaConfig.enum.map((value) => ({
      id: value,
      label: value,
    }));
  } else {
    return undefined;
  }
}

function tableForSchema(schema, entities, propertiesToIgnore = []) {
  return [
    tableHeadersForSchema(schema, propertiesToIgnore),
    ...tableEntityRows(schema, entities, propertiesToIgnore),
  ];
}

function tableHeadersForSchema(schema, propertiesToIgnore = []) {
  return tablePropertiesForSchema(schema, propertiesToIgnore).map((key) => {
    if (schema.properties[key].association) {
      return formFieldLabel(key, schema.properties[key]);
    } else {
      return snakeCaseToCapitalized(key);
    }
  });
}

function tableEntityRows(schema, entities, propertiesToIgnore = []) {
  const properties = tablePropertiesForSchema(schema, propertiesToIgnore);
  return mapEntityProperties(entities, properties, schema);
}

function getPropertyValue(entity, property, schema, options) {
  const schemaProperty = schema.properties[property];
  if (schemaProperty.association) {
    const associationName = property.split("_id")[0];
    return entity[associationName][schemaProperty.identifier];
  } else {
    return entity[property];
  }
}

function mapEntityProperties(entities, properties, schema, options) {
  return entities.map((entity) => {
    return properties.map((property) =>
      getPropertyValue(entity, property, schema, options)
    );
  });
}

function tablePropertiesForSchema(schema, propertiesToIgnore = []) {
  return Object.keys(schema.properties).filter(
    (key) => !propertiesToIgnore.includes(key)
  );
}

export {
  tableForSchema,
  formFieldType,
  formFieldLabel,
  formDropdownOptions,
  checkboxLabel,
};
