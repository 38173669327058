import { React, useState, useEffect, useCallback } from "react";
import { useRecordContext } from "react-admin";
import EntityTable from "../InternationalCompanyEnrollments/EntityTable";
import EntityForm from "../InternationalCompanyEnrollments/EntityForm";
import Dropdown from "../InternationalCompanyEnrollments/Dropdown";
import { Box } from "@mui/material";
import { BASE_URL } from "lib/constants";
import { tableForSchema } from "../InternationalCompanyEnrollments/schemaHelpers";
import HttpClient from "lib/HttpClient";

const InternationalMemberEnrollmentsTabComponent = (props) => {
  const canonicalMemberId = useRecordContext(props).external_ids.justworks_cdms;
  const [state, setState] = useState({
    error: null,
    validBenefitTypes: [],
    selectedBenefitType: "",
    memberEnrollmentsSchema: null,
    memberEnrollments: null,
    memberOfferings: null,
  });
  const [table, setTable] = useState([]);
  const benefitTypesUrl = `${BASE_URL}/international/member_benefits`;
  const schemaUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_enrollments/schema`;
  const memberEnrollmentsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_enrollments?member_id=${canonicalMemberId}`;
  const memberOfferingsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_offerings?member_id=${canonicalMemberId}`;

  const updateState = useCallback((key, result) => {
    setState((prevState) => ({ ...prevState, [key]: result }));
  }, []);

  const setSelectedBenefitType = (event) => {
    updateState("selectedBenefitType", event.target.value);
  };

  const fetchDataCallback = useCallback(
    async (url, key) => {
      try {
        const { json } = await HttpClient(url);
        updateState(key, json);
      } catch (error) {
        updateState("error", error);
      }
    },
    [updateState]
  );

  function handleSubmit() {
    fetchDataCallback(memberEnrollmentsUrl, "memberEnrollments");
  }

  // Fetch benefit types
  useEffect(() => {
    fetchDataCallback(benefitTypesUrl, "validBenefitTypes");
  }, [fetchDataCallback, benefitTypesUrl]);

  // Fetch schema, member enrollments, and member offerings
  useEffect(() => {
    if (state.selectedBenefitType) {
      fetchDataCallback(schemaUrl, "memberEnrollmentsSchema");
      fetchDataCallback(memberEnrollmentsUrl, "memberEnrollments");
    }
    if (state.selectedBenefitType === "health") {
      const memberOfferingsUrl = `${BASE_URL}/international/${state.selectedBenefitType}/member_offerings?member_id=${canonicalMemberId}`;
      fetchDataCallback(memberOfferingsUrl, "memberOfferings");
    }
  }, [
    state.selectedBenefitType,
    canonicalMemberId,
    fetchDataCallback,
    schemaUrl,
    memberEnrollmentsUrl,
    memberOfferingsUrl,
  ]);

  // Fetch table
  useEffect(() => {
    const propertiesToIgnoreInList = [
      "external_ids",
      "member_id",
      "created_at",
      "updated_at",
      `member_${state.selectedBenefitType}_offering_id`,
    ];

    async function getTable() {
      if (state.memberEnrollments && state.memberEnrollmentsSchema) {
        setTable(
          tableForSchema(
            state.memberEnrollmentsSchema,
            state.memberEnrollments,
            propertiesToIgnoreInList
          )
        );
      }
    }
    getTable();
  }, [
    state.memberEnrollments,
    state.memberEnrollmentsSchema,
    state.selectedBenefitType,
  ]);

  const propertiesToIgnoreInCreate = [
    "id",
    "external_ids",
    "company_health_enrollment_id",
    "company_dental_enrollment_id",
    "company_life_enrollment_id",
    "company_pension_enrollment_id",
    "company_food_voucher_enrollment_id",
    "provider",
    "country",
    "plan",
    "created_at",
    "updated_at",
  ];

  const presetFormValues = () => {
    const presetValuesHash = { member_id: canonicalMemberId };
    if (state.memberOfferings?.length > 0) {
      presetValuesHash[`member_${state.selectedBenefitType}_offering_id`] =
        state.memberOfferings[0].id;
    }
    return presetValuesHash;
  };

  const hasFormDependencies =
    state.selectedBenefitType === "health"
      ? state.memberEnrollmentsSchema && state.memberOfferings?.length >= 0
      : state.memberEnrollmentsSchema;

  return (
    <Box sx={{ margin: [2, 0, 0, 2] }}>
      <Dropdown
        label="Benefit Type"
        selected={state.selectedBenefitType}
        setSelected={setSelectedBenefitType}
        options={state.validBenefitTypes}
        sx={{ width: 200 }}
      />
      {hasFormDependencies ? (
        <EntityForm
          presetFormValues={presetFormValues()}
          schema={state.memberEnrollmentsSchema || {}}
          propertiesToIgnore={propertiesToIgnoreInCreate}
          baseApiUrl={BASE_URL + "/international"}
          createUrl={`${BASE_URL}/international/${state.selectedBenefitType}/member_enrollments?member_id=${canonicalMemberId}`}
          onSuccess={handleSubmit}
          sx={{ width: 300 }}
        />
      ) : (
        <></>
      )}
      {table.length > 0 ? <EntityTable table={table} /> : <></>}
    </Box>
  );
};

const InternationalMemberEnrollmentsTab = () => {
  return <InternationalMemberEnrollmentsTabComponent />;
};

export default InternationalMemberEnrollmentsTab;
