/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-eval */
import * as React from "react";
import {
  TextField,
  FunctionField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  BooleanField,
  ReferenceOneField,
  RichTextField,
  UrlField,
  RecordRepresentation,
  useResourceContext,
  useGetRecordRepresentation,
  ResourceContextProvider,
} from "react-admin";

import { Chip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { ContributionFieldComponent } from "./ContributionFieldComponent";
import ExternalIdFieldComponent from "./ExternalIdFieldComponent";
import Formatters from "../../../lib/Formatters";
import { MaskedSsnToggle } from "./MaskedSsnToggleComponent";

/* eslint-disable no-use-before-define */
const DefaultReference = function DefaultReference(
  attribute,
  references,
  link
) {
  const [nextReference] = references;
  // eslint-disable-next-line no-unused-vars
  const [_nextReference, _nextTarget, inverted] = nextReference || [];

  if (inverted) {
    return RemoteKeyReference(attribute, references, link);
  }
  return LocalKeyReference(attribute, references, link);
};
/* eslint-enable no-use-before-define */

const LocalKeyReference = function LocalKeyReference(
  attribute,
  references,
  link
) {
  const { label, sortable, source } = attribute;
  // eslint-disable-next-line react/destructuring-assignment
  if (references.length === 0) {
    if (!!source) {
      return <TextField source={source} />;
    }

    return <RecordRepresentation />;
  }

  const [nextReference, ...remainingReferences] = references;
  const [referenceResource, referenceField] = nextReference;
  return (
    <ReferenceField
      link={link}
      source={referenceField}
      key={label}
      label={label}
      reference={referenceResource}
      perpage={10000}
      sortable={!!sortable}
    >
      {DefaultReference(attribute, remainingReferences, false)}
    </ReferenceField>
  );
};

const RemoteKeyReference = function RemoteKeyReference(
  attribute,
  references,
  link
) {
  const { label, sortable, source } = attribute;
  // eslint-disable-next-line react/destructuring-assignment
  if (references.length === 0) {
    if (!!source) {
      return <TextField source={source} />;
    }

    return <RecordRepresentation />;
  }

  const [nextReference, ...remainingReferences] = references;
  const [referenceResource, referenceField] = nextReference;
  return (
    <ReferenceOneField
      link={link}
      target={referenceField}
      key={label}
      label={label}
      reference={referenceResource}
      perpage={10000}
      sortable={!!sortable}
    >
      {DefaultReference(attribute, remainingReferences, false)}
    </ReferenceOneField>
  );
};

const MultiReference = function MultiCreate(
  attribute,
  references,
  parentResource
) {
  const { source, label, sortOrder } = attribute;
  const getRecordRepresentation = useGetRecordRepresentation(parentResource);

  // eslint-disable-next-line react/destructuring-assignment
  if (references.length === 0) {
    return (
      <ResourceContextProvider value={parentResource}>
        <SingleFieldList linkType="show">
          <FunctionField
            key={label}
            render={(record) => (
              <Chip label={getRecordRepresentation(record)} />
            )}
          />
        </SingleFieldList>
      </ResourceContextProvider>
    );
  }

  const [nextReference, ...remainingReferences] = references;
  const [referenceResource, referenceField] = nextReference;
  return (
    <ReferenceArrayField
      source={referenceField}
      key={label}
      label={label}
      reference={referenceResource}
      perpage={10000}
      sort={{ field: source, order: sortOrder || "ASC" }}
    >
      {MultiReference(attribute, remainingReferences, referenceResource)}
    </ReferenceArrayField>
  );
};

const Reference = function Reference(attribute) {
  const { type, reference } = attribute;
  let component = {};
  switch (type) {
    case "multi-reference":
      component = MultiReference(attribute, reference, "show");
      break;
    default:
      component = DefaultReference(attribute, reference, "show");
      break;
  }
  return component;
};

const SelfIdReference = function SelfIdReference({ attribute }) {
  const { source, label, sortable } = attribute;
  const resource = useResourceContext();

  return (
    <>
      <p
        style={{
          fontSize: "0.75em",
          marginBottom: "0.2em",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <span>ID</span>
      </p>
      <ReferenceField
        label={label}
        key={label}
        source={source}
        reference={resource}
        sortable={!!sortable}
        link="show"
      >
        <TextField source="id" />
      </ReferenceField>
    </>
  );
};

const Text = function Text(attribute) {
  const { source, label, sortable } = attribute;
  if (source === "id") {
    return <SelfIdReference attribute={attribute} />;
  }
  return (
    <TextField
      label={label}
      key={label}
      source={source}
      sortable={!!sortable}
    />
  );
};

const Ssn = function Ssn(attribute) {
  const { source, label, sortable } = attribute;
  return (
    <MaskedSsnToggle
      label={label}
      key={label}
      source={source}
      sortable={!!sortable}
    />
  );
};

const Boolean = function Boolean(attribute) {
  const { source, label, sortable } = attribute;
  return (
    <BooleanField
      label={label}
      key={label}
      source={source}
      sortable={!!sortable}
    />
  );
};

const Conditional = function Conditional(attribute) {
  const {
    source,
    label,
    sortable,
    condition: { field, statement },
  } = attribute;
  return (
    <FunctionField
      label={label}
      key={label}
      source={source}
      sortable={!!sortable}
      render={(record) => {
        const fieldPresent = record[field] !== undefined;
        // This is so we can dynamically pass in the conditional statement.
        // Generally eval() is not preferred, but we are strictly using this with internal tools config.
        return eval(`${fieldPresent} && ${statement}`) ? (
          <CheckIcon />
        ) : (
          <CloseIcon />
        );
      }}
    />
  );
};

function jsonField(source, label) {
  return (record) => {
    return (
      <RichTextField
        label={label}
        source={source}
        record={{
          [source]: `<pre>${JSON.stringify(record[source], null, 2)}</pre>`,
        }}
      />
    );
  };
}

const Json = function Json(attribute) {
  const { source, label, sortable } = attribute;
  return (
    <FunctionField
      label={label}
      key={label}
      source={source}
      sortable={!!sortable}
      render={jsonField(source, label)}
    />
  );
};

const ExternalId = function (attribute) {
  const { source, label, external_id_owner } = attribute;
  return (
    <ExternalIdFieldComponent
      source={source}
      label={label}
      external_id_owner={external_id_owner}
    />
  );
};

const Hours = function Hours(attribute) {
  const { source, label, sortable } = attribute;
  const { format } = Formatters.hours;
  return (
    <FunctionField
      label={label}
      key={label}
      source={source}
      sortable={!!sortable}
      render={(record) => format(record[source])}
    />
  );
};

const Contribution = function Contribution(attribute) {
  const { source, label, sortable } = attribute;

  return (
    <ContributionFieldComponent
      key={source}
      source={source}
      labelPrefix={label}
      label=""
      sortable={sortable}
    />
  );
};

const Url = function Url(attribute) {
  const { source, label } = attribute;

  return (
    <UrlField
      key={label}
      source={source}
      label={label}
      target="_blank"
      rel="noopener"
    />
  );
};

const MultiText = function MultiText(attribute) {
  const { source, label } = attribute;
  return (
    <FunctionField
      key={label}
      label={label}
      source={source}
      render={(record) => {
        return record[source].map((item) => (
          <Chip key={item} label={item} sx={{ margin: "0 0 5px 3px" }} />
        ));
      }}
    />
  );
};

const DefaultInput = function DefaultInput(attribute) {
  const { type } = attribute;
  let component = {};
  switch (type) {
    case "multi-checkbox":
      component = MultiText(attribute);
      break;
    case "contribution":
      component = Contribution(attribute);
      break;
    case "hours":
      component = Hours(attribute);
      break;
    case "boolean":
      component = Boolean(attribute);
      break;
    case "json":
      component = Json(attribute);
      break;
    case "external_id":
      component = ExternalId(attribute);
      break;
    case "conditional":
      component = Conditional(attribute);
      break;
    case "ssn":
      component = Ssn(attribute);
      break;
    case "url":
      component = Url(attribute);
      break;
    default:
      component = Text(attribute);
      break;
  }
  return component;
};

const ShowableAttribute = function ShowableAttribute(attribute) {
  const { reference } = attribute;
  let component = {};
  if (reference) {
    component = Reference(attribute, reference);
  } else {
    component = DefaultInput(attribute);
  }
  return component;
};

export default ShowableAttribute;
