import * as React from "react";
import { useRecordContext, ShowButton } from "react-admin";
import { ActionButton } from "../ActionButton";
import { useGetProcessStatus } from "../../../../lib/Hooks";
import { ProcessStatus } from "../ProcessManager/components";

const CompanyInsuranceQuoteRequestProcessingManagerComponant =
  function CompanyInsuranceQuoteRequestProcessingManagerComponant(props) {
    const companyInsuranceQuoteRequestId = useRecordContext(props).id;
    const { data, isLoading, loadingError } = useGetProcessStatus(
      "company_insurance_quote_request_processing",
      companyInsuranceQuoteRequestId
    );

    const {
      data: automatedData,
      isLoading: isLoadingAutomated,
      loadingError: loadingErrorAutomated,
    } = useGetProcessStatus(
      "company_insurance_quote_request_processing_automated",
      companyInsuranceQuoteRequestId
    );

    if (loadingError) {
      return (
        <div>
          <p>Error</p>
        </div>
      );
    }

    if (loadingErrorAutomated) {
      return (
        <div>
          <p>Error loading automated workflow</p>
        </div>
      );
    }

    if (isLoading || isLoadingAutomated) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }

    // Prioritize showing the automated workflow if it is available
    if (automatedData.started) {
      return (
        <div>
          <p>AUTOMATED CENSUS FORM HERE:</p>
          <ProcessStatus status={automatedData} />
          <ShowButton
            resource="workflow_runs"
            record={{ id: automatedData.run_id }}
            label="Details"
          />
        </div>
      );
    }

    if (data.started) {
      return (
        <div>
          <ProcessStatus status={data} />
          <ShowButton
            resource="workflow_runs"
            record={{ id: data.run_id }}
            label="Details"
          />
        </div>
      );
    }

    // Only show the ability to start a regular workflow if the automated workflow is not available
    return (
      <div>
        <ProcessStatus status={data} />
        <ActionButton
          resource="company_insurance_quote_request_processing"
          id={companyInsuranceQuoteRequestId}
          action="kickoff"
          label="Start Process"
        />
      </div>
    );
  };

const CompanyInsuranceQuoteRequestProcessingManager =
  function CompanyInsuranceQuoteRequestProcessingManager() {
    return (
      <CompanyInsuranceQuoteRequestProcessingManagerComponant
        label="Workflow"
        key="CompanyInsuranceQuoteRequestProcessingManagerComponant"
      />
    );
  };

export default CompanyInsuranceQuoteRequestProcessingManager;
